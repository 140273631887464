@tailwind base;
@tailwind components;
@tailwind utilities;

.editorstyles ul {
    display: block;
    list-style-type: disc;
    padding-left: 20px;
}

.editorstyles ol {
    display: block;
    list-style-type: decimal;
    padding-left: 20px;
}

.editorstyles a {
    color: #00bfff;
    text-decoration: none;
}

.editorstyles a:hover {
    text-decoration: underline;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: gray;
}

body {
    overflow-x: hidden;
}